@import "../../frontend/primitives/button/button.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer component {
  h1 {
    @apply !tw-text-pretty !tw-font-bold;
  }

  .tw-pill {
    @apply tw-mx-1 tw-my-[5px] tw-inline-block tw-rounded-sm tw-border tw-border-solid tw-bg-white tw-px-2 tw-py-1 tw-text-sm !tw-text-grey-600;
  }

  .tw-pill-grey {
    @apply tw-border-grey-50;
  }

  .tw-pill-blue {
    @apply tw-border-blue-50 hover:tw-bg-blue-50 hover:!tw-no-underline;
  }

  .tw-field-icon {
    @apply tw-mr-4 tw-h-6 tw-w-6 tw-flex-none tw-bg-100 tw-bg-center tw-bg-no-repeat tw-align-middle;
  }

  .tw-btn-icon {
    @apply tw-mr-2 tw-h-[21px] tw-w-[21px] tw-flex-none tw-bg-100 tw-bg-center tw-bg-no-repeat tw-align-middle;
  }

  .tw-hide-button-sticky-bar {
    @apply md:tw-mt-[-88px];
  }

  .tw-link {
    @apply tw-text-sm tw-font-medium tw-text-orange-400;
  }

  .tw-discover-hero-heading {
    span {
      @apply tw-ml-2 tw-mr-2 tw-rounded-sm tw-bg-orange-15 tw-px-3 tw-text-orange xl:tw-ml-0;
    }
  }
}
